<template>
  <section class="box box-solid">
    <div>
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs nav-justified" ref="nav">
          <li
            v-bind:class="{active: currentTab == 'summary'}"
            @click="currentTab = 'summary'"
          >
            <a
              href="#tab-user-plan-summary"
              data-testid="summary-tab"
              aria-expanded="true"
              data-toggle="tab"
            >
              <i class="fa fa-credit-card"></i>
              <span class="hidden-xs hidden-sm">
                {{ $t("my_plan") }}
              </span>
              <span class="hidden-md hidden-lg">
                {{ $t("summary") }}
              </span>
            </a>
          </li>
          <li
            v-bind:class="{
              active: currentTab == 'billing_information'
            }"
            @click="currentTab = 'billing_information'"
          >
            <a
              href="#tab-user-plan-billing-information"
              data-testid="billing-information-tab"
              aria-expanded="true"
              data-toggle="tab"
            >
              <i class="fa fa-edit"></i>
              {{ $t("billing_information") }}
            </a>
          </li>
          <li
            v-bind:class="{active: currentTab == 'transactions'}"
            @click="currentTab = 'transactions'"
          >
            <a
              href="#tab-user-plan-transactions"
              data-testid="transactions-tab"
              aria-expanded="true"
              data-toggle="tab"
            >
              <i class="fa fa-usd"></i> {{ $t("transactions") }}
            </a>
          </li>
          <li
            v-if="usesPaymentGateway"
            v-bind:class="{active: currentTab == 'tax_invoices'}"
            @click="currentTab = 'tax_invoices'"
          >
            <a
              href="#tab-user-plan-tax-invoices"
              data-testid="tax-invoices-tab"
              aria-expanded="true"
              data-toggle="tab"
            >
              <i class="fa fa-file-text-o"></i>
              {{ $t("tax_invoices") }}
            </a>
          </li>
          <li
            v-if="usesPaymentGateway"
            v-bind:class="{active: currentTab == 'invoices'}"
            @click="currentTab = 'invoices'"
          >
            <a
              href="#tab-user-plan-invoices"
              data-testid="invoices-tab"
              aria-expanded="true"
              data-toggle="tab"
            >
              <i class="fa fa-money"></i> {{ $t("invoices") }}
            </a>
          </li>
        </ul>
        <div class="tab-content panel-content" ref="tabs">
          <div
            id="tab-user-plan-summary"
            class="tab-pane"
            v-bind:class="{active: currentTab == 'summary'}"
          >
            <UsageStatistics
              :planName="planName"
              :maximumData="maximumData"
              :commitment="commitment"
              :planValue="planValue"
              :contractState="contractState"
              :expiresAt="expiresAt"
              @upgrade-plan="upgradePlan"
            >
            </UsageStatistics>
          </div>
          <div
            id="tab-user-plan-invoices"
            class="tab-pane"
            v-bind:class="{active: currentTab == 'invoices'}"
          >
            <UserBillingInvoicePicker
              :busy="busy"
              v-if="tabLoaded('invoices')"
            />
          </div>
          <div
            id="tab-user-plan-tax-invoices"
            class="tab-pane"
            v-bind:class="{active: currentTab == 'tax_invoices'}"
          >
            <UserBillingTaxInvoicePicker
              :busy="busy"
              v-if="tabLoaded('tax_invoices')"
            />
          </div>
          <div
            id="tab-user-plan-billing-information"
            class="tab-pane"
            v-bind:class="{
              active: currentTab == 'billing_information'
            }"
          >
            <UserProfileBillingInformation @loading="loading" />
          </div>
          <div
            id="tab-user-plan-transactions"
            class="tab-pane tab-user-plan-transactions"
            v-bind:class="{active: currentTab == 'transactions'}"
          >
            <UserPlanTransactions @loading="loading" />
          </div>
        </div>
      </div>
      <div class="terms">
        <a href="/terms_of_service" target="_blank">
          <i class="fa fa-external-link" style="margin-right: 5px"></i>
          {{ $t("page.terms_of_service") }}
        </a>
      </div>
      <Spin v-if="busy" />
    </div>

    <Modal
      :open="showUpgradePlan"
      @update:open="showUpgradePlan = $event ? true : false"
      @loading="loading"
      :title="$t('upgrade_plan')"
      :footer="false"
      iconClass="fa fa-arrow-circle-up"
      :fitContent="false"
      :backdrop="busy ? 'static' : 'true'"
      :component_busy="busy"
    >
      <template #content>
        <component
          :is="planUpgrade"
          @loading="loading"
          @requestPlanChange="requestPlanChange"
        />
      </template>
    </Modal>
  </section>
</template>

<script>
import UserPlanTransactions from "@/components/user-plan/user-plan-transactions.vue";
import UserProfileBillingInformation from "@/components/user-plan/user-profile-billing-information.vue";
import UserBillingInvoicePicker from "@/components/user-plan/user-billing-invoice-picker.vue";
import UserBillingTaxInvoicePicker from "@/components/user-plan/user-billing-tax-invoice-picker.vue";
import UserPlanUpgradeA from "@/components/user-plan/user-plan-upgrade-a.vue";
import UserPlanUpgradeB from "@/components/user-plan/user-plan-upgrade-b.vue";
import UsageStatistics from "@/components/statistics/usage-statistics.vue";
import Modal from "@/components/widgets/modal.vue";
import Spin from "@/components/spin";

import {contractStateConst} from "@/assets/constants.js";

export default {
  name: "UserPlanPanel",
  components: {
    UserPlanTransactions,
    UserProfileBillingInformation,
    UserBillingInvoicePicker,
    UserBillingTaxInvoicePicker,
    UserPlanUpgradeA,
    UserPlanUpgradeB,
    UsageStatistics,
    Modal,
    Spin
  },
  props: {
    tab: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      busy: false,
      requestPlan: "none",
      currentTab: "summary",
      loadedTabs: [],
      fitContent: true,
      showUpgradePlan: false
    };
  },
  watch: {
    currentTab(n) {
      if (this.loadedTabs.indexOf(n) == -1) this.loadedTabs.push(n);
    }
  },
  methods: {
    loading(state) {
      this.busy = state;
    },
    requestPlanChange(option) {
      this.requestPlan = option;
    },
    upgradePlan() {
      this.showUpgradePlan = true;
    },
    tabLoaded(tabName) {
      return this.loadedTabs.indexOf(tabName) != -1;
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    usesPaymentGateway() {
      if (this.isTest) return false;
      return this?.contract && this?.contract?.uses_payment_gateway;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    plan() {
      return this.contract && this.contract.billing
        ? this.contract.billing.plan || {name: this.isDemo ? "Demo" : "-"}
        : null;
    },
    planUpgrade() {
      if (this.requestPlan == "UserPlanUpgradeB") {
        return UserPlanUpgradeB;
      }
      if (this.requestPlan == "UserPlanUpgradeA") {
        return UserPlanUpgradeA;
      }
      return !this.contract ||
        this.contract.pricing_by_data_range == undefined ||
        this.contract.pricing_by_data_range
        ? UserPlanUpgradeB
        : UserPlanUpgradeA;
    },
    planInfo() {
      return this.plan.name;
    },
    planName() {
      return this?.plan?.name?.replace("_", " ") || "";
    },
    contractState() {
      return this?.contract?.contract_state?.name || "";
    },
    expiresAt() {
      return this?.contract?.expires_at || null;
    },
    planValue() {
      return this?.contract?.amount_to_be_paid;
    },
    maximumData() {
      return `${this?.contract?.maximum_data || 0} ${this.$tc("data", 2)}`;
    },
    commitment() {
      return this.$t(this?.contract?.is_yearly ? "yearly" : "monthly");
    }
  }
};
</script>

<style scoped>
.nav-tabs-custom {
  box-shadow: none;
}
.panel-content {
  min-height: 800px;
}
.sep {
  color: #999;
}
.plan-name {
  color: #538a55;
  /* font-weight: 600; */
  text-shadow: 1px 0px 0px #cdcdcd;
}
.plan-data {
  color: #538a55;
  /* font-weight: 600; */
  text-shadow: 1px 0px 0px #cdcdcd;
}

.plan-commitment {
  color: #538a55;
  /* font-weight: 600; */
  text-shadow: 1px 0px 0px #cdcdcd;
}

.modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.terms {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.skin-dark .terms a {
  color: #b8c7ce;
}

.skin-dark .terms a:hover {
  color: #fff;
}

.tab-user-plan-transactions::v-deep > section > section.searchable-table {
  width: calc(100% - 5px);
}

ul > li > a > i {
  min-width: 18px;
  margin-right: 5px;
}
::v-deep .modal-dialog {
  min-width: 1200px;
}

@media (max-width: 1720px) {
  ::v-deep .modal-dialog {
    min-width: 1000px;
  }
}
@media (max-width: 1200px) {
  ::v-deep .modal-dialog {
    min-width: 1190px;
  }
}
@media (max-width: 992px) {
  ::v-deep .modal-dialog {
    min-width: 940px;
  }
}
@media (max-width: 768px) {
  ::v-deep .modal-dialog {
    min-width: inherit;
  }
}
</style>
