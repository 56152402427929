import { render, staticRenderFns } from "./user-plan-commitment-toggle.vue?vue&type=template&id=0cb0ad22&scoped=true&"
import script from "./user-plan-commitment-toggle.vue?vue&type=script&lang=js&"
export * from "./user-plan-commitment-toggle.vue?vue&type=script&lang=js&"
import style0 from "./user-plan-commitment-toggle.vue?vue&type=style&index=0&id=0cb0ad22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb0ad22",
  null
  
)

export default component.exports