<template>
  <form class="me" action="" autocomplete="off" v-on:submit.prevent="submit">
    <div class="row" style="margin-bottom:10px;">
      <div class="col-md-12">
        <label for="email">{{ $t("email") }}</label>
        <input
          type="text"
          class="form-control"
          :value="loggedUser.email || ''"
          disabled
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-6">
          <label for="firstname">{{ $t("first_name") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="first_name"
            :disabled="busy"
          />
        </div>
        <div class="col-md-6">
          <label for="lastname">{{ $t("last_name") }}</label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            v-model="last_name"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="row" style="margin-top:10px;">
        <div class="col-sm-12">
          <button
            class="btn btn-primary pull-right"
            @click.stop.prevent="onUpdateProfile"
            :disabled="busy || !isProfileValid"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
    <template v-if="allowPasswordChange">
      <div class="h4">
        <strong>
          {{ $t("page.password_reset") }}
        </strong>
      </div>
      <div class="row" style="margin-top:20px;">
        <div class="col-md-6">
          <InputPassword
            v-model="previous"
            label="previous_password"
            :placeholder="$t('password')"
            :disabled="busy"
          />
        </div>
        <div class="col-md-6">
          <InputPassword
            v-model="password1"
            label="new_password"
            :placeholder="$t('password')"
            :disabled="busy"
            :use-policies="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <button
            class="btn pull-right"
            v-bind:class="{
              'btn-primary': isPasswordValid,
              'btn-default': !isPasswordValid
            }"
            :disabled="busy || !isPasswordValid"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import Auth from "@/services/auth.js";
import MixinAlert from "@/project/mixin-alert.js";
import errorMessages from "@/i18n/errors.js";
import InputPassword from "@/components/registration/input-password.vue";

export default {
  name: "UserProfileForm",
  mixins: [MixinAlert],
  components: {
    InputPassword
  },
  i18n: {
    messages: errorMessages
  },
  data() {
    return {
      previous: "",
      password1: "",

      first_name: "",
      last_name: "",
      busy: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["user/loggedUser"] || {};
    },
    allowPasswordChange() {
      if (this?.loggedUser?.user_profile) {
        return !this?.loggedUser?.user_profile?.blocks_password_change;
      }
      return false;
    },
    isProfileValid() {
      return (
        !this.busy &&
        this.first_name &&
        this.last_name &&
        this.first_name != this.last_name &&
        (this.first_name != this?.loggedUser?.first_name ||
          this.last_name != this?.loggedUser?.last_name)
      );
    },
    isPasswordValid() {
      return !this.busy && this.previous && this.password1;
    },
    isRoot() {
      return this?.loggedUser?.is_root || false;
    }
  },
  watch: {
    loggedUser: {
      handler(n) {
        if (n) {
          this.first_name = n.first_name;
          this.last_name = n.last_name;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onUpdateProfile() {
      if (this.busy || !this.isProfileValid) return;
      this.busy = true;
      let payload = {
        first_name: this.first_name,
        last_name: this.last_name
      };
      this.$store.dispatch("user/updateProfile", payload).then((ret) => {
        this.busy = false;
        if (this.validateSaveResponse(ret)) {
          this.showAlert(() => {});
        } else {
          this.showAlert();
        }
      });
    },
    submit() {
      if (this.busy) return;
      let self = this;
      if (this.previous && this.password1) {
        this.busy = true;
        let payload = {
          old_password: btoa(this.previous),
          new_password1: btoa(this.password1),
          new_password2: btoa(this.password1)
        };
        self.busy = true;
        let auth = new Auth();
        auth.changePassword(payload).then(function(ret) {
          self.busy = false;
          if (self.validateSaveResponse(ret)) {
            self.showAlert();
          } else {
            self.showAlert();
          }
          self.previous = self.password1 = self.password2 = "";
        });
      } else {
        this.alert = {
          title: self.$t("item_could_not_be_saved"),
          text: self.$t("invalid_login_or_password"),
          type: "error"
        };
        this.showAlert(function() {});
      }
    },
    showPasswordChangeMessage() {
      let message =
        this.loggedUser.user_profile.reason_password_change ||
        this.$t("password_change_needed");

      this.$swal({
        title: this.$t("password_change"),
        text: message,
        icon: "info",
        button: this.$t("ok")
      });
    }
  },
  mounted() {
    if (this.loggedUser?.user_profile?.requires_password_change) {
      this.showPasswordChangeMessage();
    }
  }
};
</script>

<style scoped>
.me {
  padding-bottom: 20px;
}
.form-control-feedback {
  pointer-events: all;
}
.h4 {
  color: #666;
}
.skin-dark .h4 {
  color: #b8c7ce;
}
</style>
